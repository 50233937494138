module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Laughing Wolf","short_name":"thelaughingwolf","start_url":"/","background_color":"#2c242a","theme_color":"#2c242a","display":"minimal-ui","icon":"src/images/laughing-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
